<template>
  <component :is="curView" :class="{hideHeader}" :visible.sync="visible" v-bind="modalProps" v-on="$listeners">
    <template slot="title">
      <slot name="title"> </slot>
    </template>
    
    <slot></slot>
    <template v-if="!hideFooter" #footer>
      <slot name="footer">
        <el-button :type="hideOK ? 'primary' : ''" @click="show(false)">{{cancelText}}</el-button>
        <el-button v-if="prepareText" type="primary" v-preventReClick @click="preparesubmit"
        >{{prepareText}}</el-button>
        <el-button v-if="!hideOK" type="primary" v-preventReClick @click="submit()"
        >{{okText}}</el-button>
      </slot>
    </template>
  </component>
</template>
<script>
import { Upload } from 'element-ui'
export default {
  name: 'UiModal',
  props: {
    onSubmit: Function,
    onPreparesubmit:Function,
    prepareText: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    hideOK: Boolean,
    isEdit: Boolean,
    drawer: Boolean,
    width: String,
    title: String,
    hideMessage: Boolean,
    hideFooter: Boolean,
    hideHeader: Boolean
  },
  data() {
    return {
      visible: false,
      curView: this.drawer ? 'ui-drawer' : 'el-dialog'
    }
  },
  computed: {
    modalProps() {
      const width = this.drawer ? { size: this.width || '500px' } : { width: this.width || '420px' }
      return {
        ...width,
        title: this.title || (this.isEdit ? '编辑' : '新增'),
        appendToBody: true,
        closeOnClickModal: false,
        wrapperClosable: true,
        ...this.$attrs
      }
    }
  },
  mounted() {
    this.show(true)
  },
  methods: {
    show(visible) {
      this.visible = visible
    },
    async submit() {
      const loading = this.$loading({
        target: this.$el.querySelector('.el-dialog') || this.$el.querySelector('.el-drawer')
      })
      try {
        const data = await this.onSubmit()
        // !this.hideMessage && this.$message.success('发送成功')
        this.$emit('success', this.isEdit, data)
        this.show(false)
      } catch (e) {
        // console.log(e.message)
      }
      loading.close()
    },
    async preparesubmit() {
      const loading = this.$loading({
        target: this.$el.querySelector('.el-dialog') || this.$el.querySelector('.el-drawer')
      })
      try {
        const data = await this.onPreparesubmit()
        !this.hideMessage && this.$message.success('发送成功')
        this.$emit('success', this.isEdit, data)
        this.show(false)
      } catch (e) {
        console.log(e.message)
      }
      loading.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.hideHeader {
  /deep/ .el-drawer__header, /deep/ .el-dialog__header {
    display: none;
  }
}
</style>