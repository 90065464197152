import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VuePlugins from "./plugins"
import "./assets/icon/iconfont.css"

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.config.productionTip = false
Vue.use(VuePlugins)

//引入
import './filters' //全局过滤器
import MD5 from "js-md5" //导入md5
import * as XLSX from 'xlsx' // 引入xlsx
import * as echarts from 'echarts' // 引入echarts
import moment from "moment"; //引入moment.js转换时间格式

//挂载
Vue.prototype.md5 = MD5
Vue.prototype.$XLSX = XLSX
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;

//全局注册组件
import Pagination from "@/components/Pagination"; //分页组件

// 全局组件挂载
Vue.component('Pagination', Pagination)

//全局获取token url
Vue.prototype.$tokenUrl = 'https://www.feixiayu.xyz/service/access_token/get',
    Vue.prototype.$export = async function(params) {
        const { fileName, action } = params || {}
        if (fileName && action) {
            this.$message('正在后台下载中，请稍候...')
            const data = await action()
            const url = window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.ms-excel;charset=UTF-8' }));
            const aLink = document.createElement('a')
            aLink.href = url
            aLink.download = fileName.replace(/(\.\w+)$/, val => ` - ${Date.now()}${val}`)
            aLink.click()
            window.URL.revokeObjectURL(url)
        } else {
            throw new Error('参数缺失！请提供参数：{fileName, action}')
        }
    },
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')