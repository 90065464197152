import { Message } from 'element-ui'
import axios from "axios";
import METHOD from "./methods";
import MD5, { base64 } from "js-md5"
import { Base64 } from 'js-base64'

const axiosInstance = axios.create({
    timeout: 10000,
    baseURL: process.env.VUE_APP_baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'token,Authorization,FxyUser',
        'Access-Control-Expose-Headers': 'token,Authorization,FxyUser',
        'Access-Control-Allow-Method': 'POST,GET,OPTIONS'
    }
});

const handleReject = ({ statusCode, message, data }, showMessage = true) => {
    showMessage && Message.error(message)
    const error = new Error(message)
    error.data = data
    error.statusCode = statusCode
    return Promise.reject(error)
}

//全局的请求拦截器
axiosInstance.interceptors.request.use(function(config) {
        if (config.url.includes('template/download')) {
            config.headers.contentType = "application/json;charset=UTF-8"
            config.responseType = "blob"
        }
        //判断浏览器存储中Authorization是否存在
        let token = window.sessionStorage.getItem('Authorization')
        let FxyUser = {
            user_id: '',
            username: ''
        }
        if (window.sessionStorage.getItem("userInfo")) {
            let user = JSON.parse(window.sessionStorage.getItem("userInfo"))
            FxyUser.user_id = user.user_id
            FxyUser.username = user.user_name
        }
        config.headers.FxyUser = encodeURIComponent(JSON.stringify(FxyUser))
        if (token) {
            let name = "short_message_service"
            let password = token
            let nameAndPass = name + ":" + password;
            config.headers.Authorization = "Basic " + Base64.encode(nameAndPass)
        } else {
            //完整的业务场景：判断没有Authorization应该返回登录页面重新登陆
            // this.$message.error('登录失效，请重新登录');

        }
        //拦截全局获取token的请求，为获取token做准备
        //判断如果请求的url中包含access_token/get，说明他是请求获取token接口，那么进入判断
        if (config.url.includes("access_token/get")) {
            //获取当前时间戳 （秒级）
            let date = parseInt(new Date().getTime() / 1000);
            //密钥拼接当前时间戳
            let m = "35e32024b74ae5e61633b7d20cd1e876" + date;
            //调用md5加对拼接后的参数加密并且设置到请求头中
            config.headers.token = MD5(m)
                //因为在登录页面发送请求时没有携带参数，所以在这里进行传参数
                //因为是post请求， 所以创建json对象， 参数为之前的时间戳
            config.data = {
                't': parseInt(date),
            }
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    });

// 是否正在刷新的标记
let isRefreshing = false
let requests = []
let tokenUrl = 'https://www.feixiayu.xyz/service/access_token/get'
axiosInstance.interceptors.response.use(function(response) {
    // // 统一打印
    // console.group("本次请求地址：" + response.config.url)
    // console.log(response);
    // console.groupEnd()
    if (response.data.code == 120001 || response.data.code == 120000) {
        if (!isRefreshing) {
            isRefreshing = true
                // 说明token过期， 刷新token
            return refreshToken().then(res => {
                // 刷新token成功，将最新的token更新到header中，同时保存在localStorage中
                let token = res.data
                axiosInstance.setToken(token)
                    // 获取当前失败的请求
                let config = response.config
                    // 重置一下配置
                config.headers.Authorization = token
                config.baseURL = '' // url已经带上了/api，避免出现/api/api的情况
                    // 已经刷新了token，将所有队列中的请求进行重试
                requests.forEach(cb => cb(token))
                requests = []
                    // 重试当前请求并返回promise
                return axiosInstance(config)
            }).catch(res => {
                console.error('refreshtoken error =>', res)
                    //刷新token失败，跳转到首页重新登录吧
                this.$router.push('/login')
                this.$message.error('登录失效，请重新登录');
            }).finally(() => {
                isRefreshing = false
            })
        } else {
            // 正在刷新token，将返回一个未执行resolve的promise
            return new Promise((resolve) => {
                // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                requests.push((token) => {
                    config.baseURL = ''
                    config.headers.Authorization = token
                    resolve(axiosInstance(config))
                })
            })
        }
    }
    return response;
}, error => {
    return Promise.reject(error);
})
axiosInstance.interceptors.response.use(function(response) {
    // nProgress.done()
    return response;
}, function(e) {
    // 对响应错误做点什么
    let res = e.response,
        { data, status } = res || {}
    let msg = res ? data.info || e.message : '请求失败，请刷新重试'

    return handleReject({ message: msg, data, statusCode: status })
});

function refreshToken() {
    // instance是当前request.js中已创建的axios实例
    return axiosInstance.post(tokenUrl, null).then(res => res.data)
}

axiosInstance.setToken = (token) => {
    axiosInstance.defaults.headers.Authorization = token
    window.sessionStorage.setItem('Authorization', token)
}



//请求方法
/**
 * 
 * @param {*} method 
 * @param {*} url 
 * @param {*} params 
 */
export function request(method, url, params) {
    switch (method) {
        case METHOD.GET:
            return GET(url, params);
        case METHOD.POST:
            return POST(url, params)
        case METHOD.PUT:
            return PUT(url, params)
        case METHOD.DELETE:
            return DELETE(url, params)
    }
}

//请求方式
function GET(url, params) {
    return axiosInstance.get(url, params);
}

function POST(url, params) {
    return axiosInstance.post(url, params);
}

function DELETE(url, params) {
    return axiosInstance.delete(url, params);
}

function PUT(url, params) {
    return axiosInstance.put(url, params);
}