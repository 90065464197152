<template>
  <c-table-view ref="View" :class="[prefix, {hasSearch}]" v-bind="viewProps" v-on="$listeners">
    <div :class="`${prefix}_search`" slot="search">
      <el-form v-show="hasSearch" inline @submit.native.prevent>
        <slot name="search"></slot>
        <el-form-item>
          <el-button icon="el-icon-search" v-preventReClick @click="onSearch" type="primary">查询</el-button>
          <el-button icon="el-icon-refresh" v-preventReClick @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
      <p style="flex:1;"></p>
      <div v-show="hasSearchRight" :class="`${prefix}_search-right`">
        <slot name="search-right"></slot>
      </div>
    </div>
    <template #actions>
      <slot name="actions"></slot>
    </template>
    <template #other>
      <slot name="other"></slot>
    </template>
    <el-table-column v-for="(_, i) in _columns" :key="i" v-bind="_">
      <template v-if="_.render" v-slot="params">
        <ui-render :render="_.render" :params="params"/>
      </template>
    </el-table-column>
    <slot></slot>
  </c-table-view>
</template>
<script>
const UiRender = {
  functional: true,
  render: (h, ctx) => ctx.props.render(h, ctx.props.params)
}
export default {
  components: { UiRender },
  props: {
    columns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { hasSearch: false, hasSearchRight: false, prefix: 'ui-table-view' }
  },
  computed: {
    viewProps() {
      return { border: true, ...this.$attrs }
    },
    _columns() {
      return this.columns.map(_ => ({
        minWidth: 100,
        showOverflowTooltip: true,
        ..._,
        prop: _.prop || _.key,
        label: _.label || _.title
      }))
    }
  },
  mounted() {
    this.hasSearch = this.$slots.search !== undefined
    this.hasSearchRight = this.$slots['search-right'] !== undefined
  },
  activated() {
    /** @type {{Table: import('element-ui').Table}} */
    const table = this.getTable()
    table && table.doLayout()
  },
  methods: {
    onSearch() {
      this.$emit('search')
    },
    onReset() {
      this.$emit('reset')
    },
    getTable() {
      return this.$refs.View.$refs.Table
    }
  }
}
</script>
<style lang="scss">
.ui-table-view {
  &.c-table-view {
    padding: 0;
    border: none;
  }
  &:not(.hasSearch) {
    .c-table-view_searchBar {
      display: none;
    }
    .c-table-view_actionBar {
      padding-top: 0;
      border-top: none;
    }
  }
  &_search {
    display: flex;
    &-right {
      margin-left: 15px;
    }
    .el-form {
      &-item__label {
        font-size: 13px;
      }
    }
  }
}
</style>