import { request } from "./core"
import METHOD from "./methods"

const APIClient = {

    install(Vue) {
        Vue.prototype.$http = this;
    },

    get(URL, params) { return request(METHOD.GET, URL, { params }) },
    post(URL, data) { return request(METHOD.POST, URL, data) },
    put(URL, data) { return request(METHOD.PUT, URL, data) },
    delete(URL, data) { return request(METHOD.DELETE, URL, data) }

}

export default APIClient;