export default function(value) {
    if (!value) return ''
    value = value.toString();
    switch (value) {
        case "phone":
            return "手机号";
        case "sum_order_count":
            return "总购买次数";
        case "sum_order_pay":
            return "总购买金额";
        case "last_order_pay":
            return "上次购买金额";
        case "last_order_item_name":
            return "上次购买商品名称";
        case "days_payable_outstanding":
            return "购买时间间隔(天)";
        case "last_order_createtime":
            return "上次购买时间";
        case "last_order_createtime_days":
            return "上次购买时间距今(天) ";
        case "first_order_createtime":
            return "首次购买时间 ";
        case "first_order_pay":
            return "首次购买金额 ";
    }

}