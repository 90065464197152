export const formatTime = () => {
    let way = new Date()
    const year = way.getFullYear(way)
    const month = way.getMonth(way) + 1
    const day = way.getDate(way)
    const hour = way.getHours(way)
    const minute = way.getMinutes(way)
    const second = way.getSeconds(way)
    const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    let d = way.getDay();
    const Week = weeks[d]
    let obj = {}

    return obj={
        timers:`${[year, month, day].map(formatNumber).join('-')}  ${[hour,minute,second].map(formatNumber).join(':')}`,
        week:`${Week}`
    }
    
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : `0${n}`
}