import ElementUI from 'element-ui';
import IviewUI from 'iview'
import AdminCoreUI from "@xview-ui/admin-core/dist/ui/element"
import './element-variables.scss';
import API from '../request/index';
import * as components from "../components";
import * as global from './global'


/**
 * @param {import('vue').VueConstructor} Vue
 */

export default (Vue, options)=>{
  Vue.use(IviewUI)
  Vue.use(ElementUI);
  Vue.use(API);
  Vue.use(AdminCoreUI);
  Vue.prototype.$ELEMENT = { size: 'small' };

  Object.keys(components).forEach(name => {
    Vue.component(`Ui${name}`, components[name])
  })

  Object.keys(global).forEach(_ => {
    window[_] = global[_]
  })

  //防止重复点击
  Vue.directive('preventReClick', {
    inserted: function (el, binding) {
      el.addEventListener( 'click', () => {
        if(!el.disabled) {
          el.disabled = true;
          setTimeout( () => {
            el.disabled = false;
          }, binding.value || 1000)
        }
      })
    }
  });

  Vue.prototype.$hBtns = function (btns) {
    if (!(btns instanceof Array)) {
      btns = [btns]
    }
    return (h, { row }) => {
      return h(
        'div',
        { class: 'ui-text-btns' },
        btns.filter(_ => _).map(_ => {
          const { label, action, props, ...obj } = _
          return h(
            'el-link',
            {
              ...obj,
              props: { type: 'primary', underline: false, ...(props || {}) },
              on: { click: () => action && action(row) }
            },
            label
          )
        })
      )
    }
  }
}