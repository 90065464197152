<template>
  <div>
    <el-menu
      :collapse="flexible"
      :collapse-transition="false"
      style="border:0;background:#000;height: 100%;position: relative;"
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      text-color="#fff"
      router>
      <div class="aside_tit" v-show="!flexible"><div class="aside_logo">FEIXIAYU</div></div>
      <div class="footer">
        <div class="footer_left" v-show="!flexible">大屏展示</div>
        <div class="footer_right">
          <i class="el-icon-s-fold" v-if="!flexible" style="font-size:24px;cursor: pointer;" @click="flexible=!flexible"></i>
          <i class="el-icon-s-unfold" v-if="flexible" style="font-size:24px;cursor: pointer;" @click="flexible=!flexible"></i>
        </div> 
      </div>
      <div class="version"  v-show="!flexible">版本信息:V1.2.1</div>
      <el-menu-item index="send-management" @click.native="$router.push('/send-management')"  v-if="hiddenSMS">
        <i class="el-icon-document"></i>
        <span slot="title">创建/管理短信模版</span>
      </el-menu-item>
        <el-submenu index="1"  v-if="hiddenSMS">
          <template slot="title">
            <i class="el-icon-files"></i> 
            <span slot="title" v-show="!flexible">短信在线发送</span>
          </template>
          <el-menu-item index="send-record" @click.native="$router.push('/send-record')" >
            <span slot="title">
            <i class="el-icon-edit-outline"></i>新建发送任务</span>
          </el-menu-item>
          <el-menu-item index="send-inquire" @click.native="$router.push('/send-inquire')" >
            <i class="el-icon-tickets"></i>
            <span slot="title">查询发送记录</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="2" v-if="hiddenSMS">
          <template slot="title">
            <i class="el-icon-folder"></i>
            <span slot="title" v-show="!flexible">短信定时发送</span>  
          </template>
          <el-menu-item index="create-timing" @click.native="$router.push('/create-timing')">
            <i class="el-icon-alarm-clock"></i>
            <span slot="title">创建定时任务</span>
          </el-menu-item>
          <el-menu-item index="query-timing" @click.native="$router.push('/query-timing')">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">查询创建记录</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="sms-inbox" @click.native="$router.push('/sms-inbox')" v-if="hiddenSMS">
          <i class="el-icon-message"></i>
          <span slot="title">短信收件箱</span>
        </el-menu-item>
        <el-menu-item index="sms-inbox" @click.native="$router.push('/financialStatements')"  v-if="hiddenK3">
          <i class="el-icon-money"></i>
          <span slot="title">K3对账</span>
        </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import menu from "@/utils/menu.json"
export default {
  data(){
    return{
      Collapse:null,
      isFold:true,
      menu,
      flexible:false,
      hiddenK3:true,
      hiddenSMS:true,
      mark:'',
    }
  },
  computed:{
    isCollapse(){
      return this.$store.state.flexibleStatus
     
    },
    userInfo(){
        return JSON.parse(window.sessionStorage.getItem("userInfo"))
  },
   
  },
  created(){
        let obj=JSON.parse(window.sessionStorage.getItem("userInfo"))
        if(obj){
          let permissions=obj.resource
        permissions.forEach(item=>{
            if(item.permissions.length > 1){
              this.hiddenK3=true;
              this.hiddenSMS=true
          }else{
             item.permissions.forEach(per=>{
                if(per == "查看K3报表"){
                  this.hiddenK3=true,
                  this.hiddenSMS=false
                }else{
                  this.hiddenSMS=true;
                  this.hiddenK3=false
            };
          })
          }
          if(item.permissions.length < 1){
            this.hiddenSMS=false
            this.hiddenK3=false
          }
         
        })
        }
       
  },
  methods:{
  }
  
}
</script>

<style lang="scss" scoped>
span{
  font-size:16px
}

/deep/.el-submenu .el-menu {
    border: none;
    background-color: #000;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100%;
  color: #000000;
  box-shadow: 6px 2px 12px 0px rgba(79, 79, 79, 0.11);
}
.el-menu-item{
  height: 60px !important;
  line-height: 60px !important;
}
.aside_tit{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-bottom: 1px solid rgba(138, 133, 133, 0.6);
  background: #000;
  .aside_logo{
    font-size: 18px;
    font-family: TRENDS;
    color: #FFFFFF;
    font-weight: 600;
  }
}
.footer{
  position: absolute;
  bottom: 16px;
  width: 200px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  .footer_left{
    width: 134px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 42px;
    height: 100%;
    cursor: pointer;
  }
  .footer_right{
    width: 45px;
    border-left: 1px solid #4F4F5E;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      color: #9C9C9C;
      text-align: center;
    }
  }
}
.version{
   width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
  }
.el-menu-item {
  i{
    color: #fff;
  }
}

.el-submenu{
  i{
    color: #fff;
  }
}

.el-submenu .el-menu-item{
  padding-left: 300px;
}

/deep/.el-menu-item .is-active {
 background: rgb(23, 24, 24) !important;
 color:#409EFF !important;
    i{
      color: #409EFF !important;
    }
  }

/deep/.el-submenu .el-menu-item {
    min-width: 180px !important; 
}
.el-menu-item:hover, .el-menu-item:focus{
  background-color: rgb(23, 24, 24) !important;
  color: #409EFF !important;
   i {
    color: #409EFF;
    }
}

.el-submenu.is-opened .el-submenu__title {
    background-image: linear-gradient(to right, #1B82DB, #20DFE1);
    background-color: #171D4F;
  }
  /deep/.el-menu--vertical{
    background: #000 !important;
  }
  .el-menu--collapse > .el-menu-item span, .el-menu--collapse > .el-submenu > .el-submenu__title span[data-v-6ba55e7a] {
    overflow: unset !important;
    visibility: unset !important;
    display: none !important;
}

</style>