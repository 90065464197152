import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        flexibleStatus: false, //侧边导航栏状态
        isRouterAlive: true,
        openTab: [], //标签页数组
        activeIndex: "/send-management",
    },
    mutations: {
        add_tabs(state, data) {
            state.openTab.push(data);
        },
        add_active_index(state, path) {
            state.activeIndex = path
        },
        delete_tabs(state, data) {
            let index = 0
            for (let option of state.openTab) {
                if (option.route === data) {
                    break;
                }
                index++;
            }
            this.state.openTab.splice(index, 1)
        },
        flexibleFn(state, data) {
            state.flexibleStatus = data; //侧边导航栏状态
        },
        refresh(state, data) { //刷新当前页面
            state.isRouterAlive = data;
            window.setTimeout(() => {
                state.isRouterAlive = true
            }, 500)
        },
    },
    actions: {},
    modules: {}
})