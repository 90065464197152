<template>
  <div id="app">
    <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
  export default {
    data(){
      return{
      }
    },
    watch : {
    '$route' (to, from) {
    }
},
    created(){
  },
    methods:{
    }
  }
</script>

<style lang="scss">
.el-table__header th {
  font-size: 13px;
  color: #ffffff;
}
.el-submenu .el-submenu__title:hover{
    border-left: rgb(13, 14, 15) !important;
    background-color: rgb(13, 14, 15) !important;
    color: #fff !important;
    i {
        color: #fff;
    }
}
.el-tooltip__popper {
background: #303133 !important;
opacity: 1 !important;
color: #fff !important;
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
.el-tooltip__popper {
  max-width: 800px;
}
.el-divider--horizontal{
  margin: 0 !important;
  background: #EBEEF5 !important;
}
.el-table{
   margin: 10px 0px 0 12px !important;
   width: 98% !important;
   color: #000 !important;
}
//黑白灰
// #app{
//    filter:progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
//     -webkit-filter: grayscale(100%);
// }
.notify-success{
    top: 1.7rem !important;
    right: 8rem !important;
}
.el-menu {
    border-right: solid 1px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #000 !important;
}

</style>
