import Vue from 'vue'

// //引入筛选条件
import filterConditions from './filterConditions'

// //引入时间过滤器
import filterTime from './filterTime'


let obj = {
    filterConditions,
    filterTime,
}
for (let i in obj) {
    Vue.filter(i, obj[i])
        // console.log("i",i);//filterPrice
        // console.log("obj",obj);//obj对象
}