<template>
  <div class="Header">
    <div class="head_left">
    </div>
    <div class="head_right">
      <span class="span_date" style="margin-right:15px;">{{timers.timers}} </span>
      <span class="span_day"> {{timers.week}}</span>
      <div class="right_refresh" @click="Refresh"><i class="el-icon-refresh-right iconls"></i></div>
      <div class="right_refresh" @click="fullScreen"><i class="el-icon-rank iconls"></i></div>
       <div class="right_avatar" v-if="this.userInfo">
        <el-dropdown @command="handDropClick">
          <div class="dropdown">
            <el-avatar :src="userInfo.avatar"></el-avatar>
            <div style="color:#000; margin-left:10px;">{{userInfo.user_name}}</div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <p class="role"><span><i class="el-icon-user"></i>&nbsp;&nbsp; 角色:</span><span>{{this.role}} </span></p>
            <el-dropdown-item icon="el-icon-switch-button" command="1">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/utils/formDate.js"
import screenfull from "screenfull"
export default {
  data(){
    return{
      flexible:false,
      timers:{},
      role:""
    }
  },
  watch:{
    flexible(data){
      this.$store.commit('flexibleFn',data)
    }
  },
  computed:{
    userInfo(){
        return JSON.parse(window.sessionStorage.getItem("userInfo"))
    }
  },
  created(){
     let obj=JSON.parse(window.sessionStorage.getItem("userInfo"))
     if(obj){
        let permissions=obj.resource
        permissions.forEach(item=>{
          this.role=item.role_name
        });
     }
       
    const timer = window.setInterval(() => {
      this.timers = formatTime()
    },1000);
    this.$once('hook:beforeDestroy', () => {       
      clearInterval(timer);                 
    })
  },
  methods:{
    Refresh(){
      this.$store.commit('refresh',false)
    },
    fullScreen(){
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    handDropClick(name){
      switch (name){
        case "1":
        window.sessionStorage.removeItem("isLogin")
        window.sessionStorage.removeItem("Authorization")
        sessionStorage.clear()
        location.reload();
        window.location.replace="/login"
        break;
      }
    },
  }
}
</script>

<style scoped lang="scss">

.role{
  font-size: 13px;
  color: #606266;
  text-align: center;
  line-height: 0;
}
.Header{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .head_left{
    display: flex;
    align-items: center;
  }
  .head_right{
    display: flex;
    align-items: center;
    padding-right: 40px;
    height: 100%;
    position: relative;
    color: #000;
    font-size: 14px;
    .right_refresh{
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconls{
        font-size: 18px;
        font-weight: bold;
      }
    }
    .right_avatar{
      display: flex;
      align-items: center;
      .el-dropdown{
        display: flex;
        align-items: center;
        .dropdown{
          display: flex;
          align-items: center;
        }
      }
    }
    .right_avatar:hover{
      cursor: pointer;
    }
    .right_refresh:hover{
      cursor: pointer;
    }
  }
}
</style>