import Vue from 'vue'
import VueRouter from 'vue-router'

//解决路由重复点击的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/entry/login.vue'),
        meta: {
            title: "登录FEIXIAYU-短信发送平台",
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () =>
            import ('../views/entry/empty.vue'),
    },
    {
        path: '/layout',
        name: 'Layout',
        component: () =>
            import ('../views/entry/layout.vue'),
        children: [{
                path: '/send-management',
                name: 'SendManagement',
                component: () =>
                    import ('../views/subjectView/management/send-management.vue'),
                meta: {
                    title: "管理/创建短信模版",
                    keepAlive: true,
                    permissions: ["发送短信"],
                    roles: "运营"
                },
            },
            {
                path: '/send-record',
                name: 'SendRecord',
                component: () =>
                    import ('../views/subjectView/record/send-record.vue'),
                meta: {
                    title: "新建发送任务",
                    keepAlive: true,
                    permissions: ["发送短信"],
                    roles: "运营"
                },
            },
            {
                path: '/send-inquire',
                name: 'SendInquire',
                component: () =>
                    import ('../views/subjectView/inquire/send-inquire.vue'),
                meta: {
                    title: "查询发送记录",
                    keepAlive: true,
                    permissions: ["发送短信"],
                    roles: "运营"
                },
            },
            {
                path: '/sms-inbox',
                name: 'SmsInbox',
                component: () =>
                    import ('../views/subjectView/inbox/sms-inbox.vue'),
                meta: {
                    title: "短信收件箱",
                    keepAlive: true,
                    permissions: ["发送短信"],
                    roles: "运营"
                },
            },
            {
                path: '/create-timing',
                name: 'CreateTiming',
                component: () =>
                    import ('../views/subjectView/timing/query-sql.vue'),
                meta: {
                    title: "创建定时任务",
                    keepAlive: true,
                    permissions: ["发送短信"],
                    roles: "运营"
                },
            },
            {
                path: '/query-timing',
                name: 'QueryTiming',
                component: () =>
                    import ('../views/subjectView/query/query-timing.vue'),
                meta: {
                    title: "查询创建记录",
                    keepAlive: true,
                    permissions: ["发送短信"],
                    roles: "运营"
                }
            },
            {
                path: '/debriefingTask',
                name: 'debriefingTask',
                component: () =>
                    import ('../views/subjectView/query/debriefingTask.vue'),
                meta: {
                    title: "查看任务报告",
                    keepAlive: false,
                    permissions: ["发送短信"],
                    roles: "运营"
                },
            },
            {
                path: '/financialStatements',
                name: 'financialStatements',
                component: () =>
                    import ('../views/subjectView/financial/financialStatements.vue'),
                hidden: true,
                meta: {
                    title: "K3对账",
                    keepAlive: true,
                    permissions: ["查看K3报表"],
                    roles: "财务"
                },
            },

        ]
    }, {
        path: '/404',
        name: 'NotFound',
        component: () =>
            import ('../views/error/404.vue'),
        meta: {
            title: "Page not found",
        },
    }, {
        path: '*',
        redirect: '/404'
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})


router.beforeEach((to, from, next) => {
    let isLogin = window.sessionStorage.getItem("isLogin")
    let user = JSON.parse(window.sessionStorage.getItem("userInfo"))
    if (!isLogin) { //未登录
        if (to.path !== '/login') {
            if (to.path == '/empty') {
                if (to.query.code) {
                    window.sessionStorage.setItem("isLogin", true)
                    return next()
                }
            }
            return next({ path: '/login' })
        } else {
            next()
        }
    } else { //一登录
        if (to.path === '/login') {
            // return next({ path: '/layout' });
        }
        next()
    };
    if (isLogin) {
        if (user) {
            let permissions = user.resource;
            permissions.forEach(item => {
                if (item.permissions.length == 2 || item.permissions.length == 0) {} else {
                    item.permissions.forEach(per => {
                        if (to.meta.permissions) {
                            if (to.meta.permissions.includes(per)) {
                                next()
                            } else {
                                next({ path: "/404" })
                            }
                        }
                    })
                }
            })
        }
    }
})

/**
 * 获取页面标题
 * @param {import('vue-router').Route} route 路由对象
 */
const getTitle = route => router.resolve(route).route.matched.map(_ => _.meta.title).filter(_ => _).join(' - ') || '未命名页面'

router.afterEach(to => {
    document.title = `${getTitle(to)}`
})

export default router