import Vue from 'vue'
import router from '../router'
import store from '../store'

const modalClass = 'ui-jsmodal'

/**
 * 表格视图混入
 * @type {import('vue').ComponentOptions} vmOptions
 */
const mixTableView = {
    data() {
        return {
            loading: false,
            tableForm: {},
            searchForm: {},
            selectedRow: [],
            tableData: { data: [], total: 0, params: { firstIndex: 1, pageSize: 10 } }
        }
    },
    computed: {
        viewProps() {
            const { params, ...tableData } = this.tableData
            return { loading: this.loading, columns: this.columns || [], ...tableData, ...params }
        },
        viewListeners() {
            return {
                change: this.getData,
                reset: this.onReset,
                search: this.onSearch,
                'selection-change': this.onSelectionChange
            }
        }
    },
    methods: {
        async getData(params = {}) {
            this.selectedRow = []
            this.loading = true
            this.tableData.params = {...this.tableData.params, ...params }
            try {
                const result = await this.fetchMethod({...this.tableData.params, ...this.tableForm })
                this.tableData.total = result.data.count
                this.tableData.data = result.data.list
            } catch (e) {
                this.tableData.data = []
            }
            this.loading = false
        },
        async delData({ rows, desc, action }) {
            await this.$confirm(desc || '确定删除选中的记录？', '删除')
            if (!(rows instanceof Array)) rows = [rows]
            await action(rows)
            this.$message.success('删除成功')
            await this.getData()
            if (this.viewProps.firstIndex > 1 && this.viewProps.data.length < 1) {
                this.getData({ firstIndex: this.viewProps.firstIndex - 1 })
            }
        },
        onSearch() {
            this.tableForm = {...this.searchForm }
            this.getData({ firstIndex: 1 })
        },
        onReset() {
            this.searchForm = {}
            this.onSearch()
        },
        onSelectionChange(selection) {
            this.selectedRow = selection
        },
        onEditSuccess(isEdit) {
            this.getData(isEdit ? {} : { firstIndex: 1 })
        }
    }
}

/**
 * 打开对话框
 * @param {import('vue').ComponentOptions} vm
 */
const openModal = (vm, props, listeners = {}) => {
    return new Vue({
        store,
        router,
        render(h) {
            return h(vm, {
                props,
                class: modalClass,
                on: {
                    ...listeners,
                    closed: this.destroy
                }
            })
        },
        methods: {
            destroy() {
                this.$el.remove()
                this.$destroy()
            }
        }
    }).$mount()
}


export {
    openModal,
    mixTableView,
}