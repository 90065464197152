<template>
  <el-drawer :custom-class="prefix" v-bind="{...$attrs, title}" v-on="$listeners">
    <div :class="`${prefix}_body`">
      <slot></slot>
    </div>
    <div :class="`${prefix}_footer`">
      <slot name="footer"></slot>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: 'UiDrawer',
  props: ['title'],
  data() {
    return { prefix: 'ui-drawer' }
  }
}
</script>
<style lang="scss">

.ui-drawer {
  .el-drawer__header {
    padding: 15px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #E4E7ED;
    font-size: 17px;
  }
  .el-drawer__body {
    height: 0;
    display: flex;
    flex-direction: column;
  }
  &_body {
    flex: 1;
    height: 0;
    padding: 20px;
    overflow-y: auto;
  }
  &_footer {
    padding: 10px 20px;
    text-align: right;
    border-top: 1px solid #EBEEF5;
  }
}
</style>